import React, { useState } from 'react'
import axiosPrivate from 'hooks/useAxiosPrivate'
import { setError } from 'features/error/errorSlice'
import { useDispatch } from 'react-redux'
import { addForm } from 'features/forms/formsSlice'
import { addWebsite } from 'features/website/websiteSlice'
// Add this import
import { useAuth } from '@clerk/clerk-react'
//Components
import Popup from 'reactjs-popup'
import { IoMdClose } from 'react-icons/io'
import { CiSquarePlus } from 'react-icons/ci'

import Loader from '../../../../components/Loading'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
const CreatingForm = () => {
  const { getToken } = useAuth()
  const dispatch = useDispatch()

  const [websiteURL, setWebsiteURL] = useState('')
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [forms, setForms] = useState([])
  const [displayForms, setDisplayForms] = useState([])
  const [selectedForms, setSelectedForms] = useState([])
  const axios = axiosPrivate()

  const onSubmit = async (e) => {
    e.preventDefault()

    let baseURL
    if (websiteURL) {
      try {
        // Prepend 'http://' if the URL starts with 'www'
        if (websiteURL.startsWith('www.')) {
          setWebsiteURL('http://' + websiteURL)
        }

        baseURL = new URL(websiteURL).origin

        // Check if the baseURL starts with 'www'
      } catch (error) {
        console.error('Invalid URL:', error)
        dispatch(setError('Invalid URL format'))

        return
      }
    }
    console.log('Form submitted')
    setLoading(true)

    try {
      // Get the token before making the request
      const token = await getToken()

      const res = await axios.post(
        '/form/scrape',
        { url: baseURL },
        { headers: { Authorization: `Bearer ${token}` } },
      )

      console.log(res.data)
      setForms(res.data.allForms)
      // const uniqueForms = Array.from(
      //   res.data
      //     .reduce((map, form) => {
      //       const key = form.id
      //       // Always set the form, which will overwrite duplicates
      //       map.set(key, form)
      //       return map
      //     }, new Map())
      //     .values(),
      // )

      setModalIsOpen(true)
      setDisplayForms(res.data.formsToDisplay)
    } catch (err) {
      dispatch(setError('Failed to scrape forms'))
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  function containsForm(selectedForms, formToCheck) {
    return selectedForms.some(
      (form) =>
        form.id === formToCheck.id &&
        JSON.stringify(form.classes) === JSON.stringify(formToCheck.classes),
    )
  }
  const onClick = (form) => {
    if (containsForm(selectedForms, form)) {
      setSelectedForms(selectedForms.filter((f) => f.id !== form.id))
    } else {
      setSelectedForms([...selectedForms, form])
    }
  }
  const addToTesting = async () => {
    let baseURL
    if (websiteURL) {
      try {
        baseURL = new URL(websiteURL).origin
        console.log(baseURL)
        // Check if the baseURL starts with 'www'
        // if (
        //   !baseURL.startsWith('https://www.') &&
        //   !baseURL.startsWith('http://www.')
        // ) {
        //   dispatch(setError('URL must start with www'))
        //   return
        // }
      } catch (error) {
        console.error('Invalid URL:', error)
        dispatch(setError('Invalid URL format'))

        return
      }
    }
    //Remove screenshot from forms
    setLoading2(true)
    const allFormsFormatted = forms.map(({ screenshot, ...rest }) => ({
      ...rest,
    }))
    const selectedFormsFormatted = selectedForms.map(
      ({ screenshot, ...rest }) => ({
        ...rest,
      }),
    )

    try {
      // Get the token before making the request
      const token = await getToken()

      const res = await axios.post(
        '/form/add-scraped',
        {
          websiteBaseUrl: baseURL,
          selectedForms: selectedFormsFormatted,
          allForms: allFormsFormatted,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )

      const forms = res.data.forms

      const newWebsite = res.data.newWebsite
      dispatch(addWebsite(newWebsite))
      forms.forEach((form) => {
        dispatch(addForm(form))
      })
      setModalIsOpen(false)
    } catch (err) {
      dispatch(setError('Failed to add forms to testing'))
    } finally {
      setLoading2(false)
    }
  }
  return (
    <div className="mt-5">
      <p className="text-sm font-semibold">Add New Form</p>
      <div className="flex items-center h-10">
        {loading ? (
          <Loader />
        ) : (
          <form className="flex gap-2" onSubmit={onSubmit}>
            <div className="flex items-center">
              <div className="hidden justify-center items-center w-20 h-full text-sm rounded-r-none sm:flex input input-bordered">
                <p>https://</p>
              </div>
              <input
                type="text"
                placeholder="Website base URL"
                className="input input-bordered input-sm w-[90vw] max-w-[200px]  xs:max-w-[300px] !bg-base-100 !text-base-content sm:!rounded-l-none h-full mb-0" // Added h-full
                onChange={(e) => setWebsiteURL(e.target.value)}
              />
            </div>
            <button className="btn btn-sm btn-primary">
              <CiSquarePlus size={20} />
              <p className="hidden xs:block"> Add Form</p>
            </button>
          </form>
        )}
      </div>
      <Popup
        open={modalIsOpen}
        closeOnDocumentClick={false}
        modal
        onClick={() => {
          setModalIsOpen((state) => !state)
        }}
      >
        <div className="flex relative flex-col gap-2 justify-center items-center w-full">
          <button
            onClick={() => {
              setModalIsOpen(false)
            }}
            className="ml-auto btn btn-circle btn-sm"
          >
            <IoMdClose />
          </button>
          <p className="font-semibold">Select forms that you want to test</p>

          <Carousel className="w-full">
            {displayForms.map((form) => (
              <div
                key={form.id}
                className={`${
                  containsForm(selectedForms, form)
                    ? 'border-primary border-[2px]'
                    : 'cursor-pointer'
                } min-w-[250px] min-h-[250px]`}
                onClick={() => onClick(form)}
              >
                <a href={form.url} className="text-blue-500 break-all">
                  {form.url}
                </a>
                <p className="break-all">{form.id}</p>
                <img
                  src={
                    form.screenshot
                      ? `data:image/png;base64,${form.screenshot}`
                      : ''
                  }
                  alt="Form screenshot"
                  className="object-cover w-full h-full"
                />
              </div>
            ))}
          </Carousel>

          {loading2 ? (
            <div>
              <Loader />
            </div>
          ) : (
            <button className="btn btn-primary" onClick={addToTesting}>
              Add to testing
            </button>
          )}
        </div>
      </Popup>
    </div>
  )
}

export default CreatingForm
