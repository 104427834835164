import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  website: 0,
  integration: '',
}

const apiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true
      state.website = action.payload.website
      state.integration = action.payload.integration
    },
    close: (state) => {
      state.isOpen = false
    },
  },
})
export const { open, close } = apiKeysSlice.actions
export default apiKeysSlice.reducer
