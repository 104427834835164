import { createSlice } from '@reduxjs/toolkit'
const initialState = {}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      return action.payload
    },
    resetUserInfo: () => {
      return initialState
    },
    updateUserInfo: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})
export const { setUserInfo, resetUserInfo, updateUserInfo } =
  userInfoSlice.actions
export default userInfoSlice.reducer
