import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setTheme } from '../features/theme/themeSlice'
import { FaArrowDown } from 'react-icons/fa6'
import { FiSun, FiMoon } from 'react-icons/fi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useUser } from '@clerk/clerk-react'
//Images
import LogoLight from '../assets/Formibly-white-logo.png'
import LogoDark from '../assets/Formibly-logo.png'

const DashboardNavTop = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.theme.theme)

  const [dropdownsOpen, setDropdownsOpen] = useState({
    dropdown1: false,
    dropdown2: false,
  })

  const { user } = useUser()

  // Toggle dropdown visibility
  const toggleDropdown = (dropdownId) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [dropdownId]: !prevState[dropdownId],
    }))
  }

  // Close dropdown
  const closeDropdown = (dropdownId) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [dropdownId]: false,
    }))
  }
  //Theme
  const handleThemeChange = (e) => {
    const newTheme = e.target.checked ? 'formiblyDark' : 'formibly'
    document.documentElement.setAttribute('data-theme', newTheme)
    dispatch(setTheme(newTheme))
    localStorage.setItem('theme', newTheme)
  }
  return (
    <div className="flex w-full bg-base-200 min-h-[60px]">
      <div className="flex flex-1 justify-start items-center ml-5">
        <div className="dropdown">
          <div
            tabIndex={0}
            role="button"
            className="m-1 dropdown-toggle"
            onClick={() => toggleDropdown('dropdown1')}
          >
            <GiHamburgerMenu />
          </div>
          {dropdownsOpen.dropdown1 && (
            <ul
              tabIndex={0}
              className="dropdown-content menu rounded-box z-[1] w-52 p-2 shadow bg-base-200"
            >
              <li onClick={() => closeDropdown('dropdown1')}>
                <Link to="/forms">Forms</Link>
              </li>
              <li onClick={() => closeDropdown('dropdown1')}>
                <Link to="/websites">Websites</Link>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="flex flex-1 justify-center items-center">
        <Link to="/" className="flex items-center px-5 h-full">
          {theme === 'formibly' ? (
            <img
              src={LogoDark}
              alt="formibly logo"
              className="w-[140px] min-w-[100px]"
            />
          ) : (
            <img
              src={LogoLight}
              alt="formibly logo"
              className="w-[140px] min-w-[100px]"
            />
          )}
        </Link>
      </div>
      <div className="flex flex-1 justify-end items-center">
        <div
          className="px-5 py-2  dropdown dropdown-bottom dropdown-end w-[100%] max-w-[200px]  relative"
          onClick={() => toggleDropdown('dropdown2')}
        >
          <div className="absolute h-[50%] w-[1px] bg-neutral top-[50%] left-[10px] translate-y-[-50%]" />
          <div
            tabIndex={0}
            role="button"
            className="flex gap-2 justify-between items-center"
          >
            <img
              src={user?.imageUrl}
              className="hidden object-cover w-12 h-12 rounded-full sm:block"
              alt="User profile"
            />
            <div className="flex flex-col justify-center font-semibold">
              <p>{user?.firstName}</p>
            </div>

            <div className="flex justify-center items-center w-5 h-5">
              <FaArrowDown />
            </div>
          </div>
          {dropdownsOpen.dropdown2 && (
            <ul
              tabIndex={0}
              className="dropdown-content menu  rounded-box z-[1] w-52 p-2 shadow mt-4 bg-base-200 "
            >
              <li>
                <Link
                  to="/settings"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  User settings
                </Link>
              </li>
              <li>
                <Link
                  to="/documents"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  Documents
                </Link>
              </li>
              <li>
                <Link
                  to="/billing"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  Billing
                </Link>
              </li>
              <li>
                <Link
                  to="/instructions"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown2')
                  }}
                >
                  Instructions
                </Link>
              </li>
              <li>
                <Link
                  to="/support"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown5')
                  }}
                >
                  Support
                </Link>
              </li>
              <li>
                <Link
                  to="/reports"
                  onClick={(e) => {
                    e.stopPropagation()
                    closeDropdown('dropdown4')
                  }}
                >
                  Reports
                </Link>
              </li>
              <li>
                <div className="flex justify-between">
                  {theme === 'formiblyDark' ? <FiMoon /> : <FiSun />}
                  <input
                    type="checkbox"
                    className="toggle theme-controller"
                    value={'formiblyDark'}
                    checked={theme === 'formiblyDark'}
                    onChange={(e) => handleThemeChange(e)}
                  />
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashboardNavTop
