import React from 'react'
//Hooks
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//Components
import { FiEdit3 } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import 'react-toggle/style.css'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { FaCircle } from 'react-icons/fa6'
import { FaRegEye } from 'react-icons/fa'
import Loading from '../../../../components/Loading'

//Utils
import {
  editWebsiteNameAsync,
  removeWebsiteAsync,
  toggleNotifications,
  toggleActive,
} from 'features/website/websiteSlice'
import { setError } from 'features/error/errorSlice'
import { open } from 'features/apiKeys/apiKeysSlice'
const WebsiteV2 = ({ website, loading }) => {
  const dispatch = useDispatch()
  const { id, name, integrations, forms } = website
  const allForms = useSelector((state) => state.forms)
  //Toogles
  const [notifications, setNotifications] = useState(website.notifications)
  const [active, setActive] = useState(website.active)
  //Editing name
  const [websiteName, setWebsiteName] = useState(name)
  const [editing, setEditing] = useState(false)
  //Integrations
  const options = [
    {
      label: 'Gravity',
      value: 'Gravity',
    },
    { label: 'HubSpot', value: 'HubSpot' },
    { label: 'Formidable', value: 'Formidable' },
    { label: 'Typeform', value: 'Typeform' },
    { label: 'Jotform', value: 'Jotform' },
  ]
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])
  //Forms

  const websiteForms = allForms.filter((form) => forms.includes(form.id))

  const failedForms = websiteForms.filter((form) => form.status === 'Failed')
  const totalForms = forms?.length
  //Removing website
  const removeWebsite = () => {
    try {
      dispatch(removeWebsiteAsync({ id }))
    } catch (err) {
      dispatch(setError('Failed to remove website'))
    }
  }
  //Notifications
  const handleNotificationsToggle = () => {
    try {
      dispatch(toggleNotifications({ id, notifications: !notifications }))
      setNotifications(!notifications)
    } catch (err) {
      dispatch(setError('Failed to toggle notifications'))
    }
  }
  //Active
  const handleActiveToggle = () => {
    try {
      dispatch(toggleActive({ id, active: !active }))
      setActive(!active)
    } catch (err) {
      dispatch(setError('Failed to chaghed active status'))
    }
  }
  //Update name
  const updateName = () => {
    try {
      dispatch(editWebsiteNameAsync({ id, name: websiteName }))
    } catch (err) {
      dispatch(setError('Failed to edit website name'))
    }
  }
  return (
    <div className="flex flex-col justify-center items-center p-4 mb-4 bg-base-100 tabShape lg:flex-row-reverse">
      <button
        className="self-end lg:self-center btn btn-circle btn-sm lg:hidden"
        onClick={removeWebsite}
      >
        <RiDeleteBin5Fill />
      </button>
      <button
        className="hidden self-end lg:self-center btn btn-sm btn-primary lg:block"
        onClick={removeWebsite}
      >
        remove
      </button>
      <div className="flex flex-col w-full gap-2  xs:flex-row max-w-[240px] xs:max-w-none">
        <div className="flex flex-col flex-1 gap-2 md:flex-row">
          <div className="flex-1">
            <div className="flex justify-between items-center h-full xs:max-w-[160px] md:max-w-[160px] lg:max-w-[170px] xl:max-w-[180px] 2xl:max-w-[200px]">
              {editing ? (
                <input
                  type="text"
                  value={websiteName}
                  onBlur={() => {
                    setEditing(false)
                    updateName()
                  }}
                  onChange={(e) => {
                    setWebsiteName(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      setEditing(false)
                      updateName()
                    }
                  }}
                  className="w-full input input-bordered"
                />
              ) : (
                <p className="overflow-hidden pr-4 truncate">{name}</p>
              )}
              {!editing && (
                <button
                  onClick={() => {
                    setEditing(!editing)
                    if (!editing) {
                      updateName()
                    }
                  }}
                >
                  <FiEdit3 />
                </button>
              )}
            </div>
          </div>
          <div className="flex flex-1 items-center">
            <div className='w-full'>
              <p className="text-xs">API Key's / Tokens</p>
              <div className="w-full dropdown xs:max-w-[160px]">
                <div
                  tabIndex={0}
                  role="button"
                  className="flex gap-2 justify-between items-center px-1 py-2 w-full rounded-xl border"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <p> {selectedOption.label}</p>

                  <button
                    className={`btn btn-xs btn-primary ${
                      integrations.find(
                        (integration) =>
                          integration.type === selectedOption.value,
                      ) && ' !btn-success'
                    } `}
                    onClick={(e) => {
                      e.stopPropagation() // Prevent the dropdown from toggling when button is clicked
                      dispatch(
                        open({
                          website: website.id,
                          integration: selectedOption.value,
                        }),
                      )
                    }}
                  >
                    {integrations.find(
                      (integration) =>
                        integration.type === selectedOption.value,
                    )
                      ? 'change'
                      : 'connect'}
                  </button>
                </div>
                {dropdownOpen && (
                  <ul
                    tabIndex={0}
                    className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52"
                  >
                    {options.map((option) => (
                      <li key={option.label} ke>
                        <p
                          className="flex justify-between"
                          onClick={() => {
                            setSelectedOption(option)
                            setDropdownOpen(false)
                          }}
                        >
                          {option.label}
                          <FaCircle
                            className={`${
                              integrations.find(
                                (integration) =>
                                  integration.type === option.value,
                              ) && '!text-success'
                            } text-error`}
                          />
                        </p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 gap-2 md:flex-row">
          <div className="flex flex-1 items-center">
            {loading ? (
              <Loading />
            ) : (
              <div className="w-full xs:max-w-[160px]">
                <div className="flex justify-between">
                  <p>Total forms: {totalForms}</p>

                  <Link
                    to="/forms"
                    state={{ website: website.id, filter: true }}
                  >
                    <button className="btn btn-circle btn-xs">
                      <FaRegEye />
                    </button>
                  </Link>
                </div>
                <div className="flex justify-between mt-2">
                  <p>Failed forms: {failedForms.length}</p>
                  <Link
                    to="/forms"
                    state={{ website: website.id, filter: true, failed: true }}
                  >
                    <button className="btn btn-circle btn-xs">
                      <FaRegEye />
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-1 items-center">
            <div className="flex flex-col flex-1 justify-center text-sm  xs:max-w-[150px]">
              <div className="flex justify-between items-center mb-2">
                <p>Notifications: </p>
                <input
                  type="checkbox"
                  className="mb-0 toggle toggle-primary toggle-sm"
                  defaultChecked={notifications}
                  onChange={handleNotificationsToggle}
                />
              </div>
              <div className="flex justify-between items-center">
                <p>Active: </p>
                <input
                  type="checkbox"
                  className="mb-0 toggle toggle-primary toggle-sm"
                  defaultChecked={active}
                  onChange={handleActiveToggle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebsiteV2
