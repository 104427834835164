import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'
const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl="/"
    >
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/*" element={<App />}></Route>
          </Routes>
        </Provider>
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>,
)
