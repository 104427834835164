import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: 'light',
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload // Update theme based on action payload
    },
  },
})
export const { setTheme } = themeSlice.actions
export default themeSlice.reducer
