import axios, { axiosPrivate } from 'api/axios'
//Hooks
import { useDispatch } from 'react-redux'
//Utils
import { setAuth } from 'features/auth/authSlice'
const useRefreshToken = () => {
  const dispatch = useDispatch()

  const refresh = async () => {
    const response = await axios.get('/user/refresh-token', {
      withCredentials: true,
    })

    const imageRes = await axiosPrivate.get(
      `/profile-picture/${response.data.profilePhoto}`,
      {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${response.data.accessToken}` },
      },
    )

    const blobUrl = URL.createObjectURL(imageRes.data)
    dispatch(
      setAuth({
        accessToken: response.data.accessToken,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        profilePhoto: blobUrl,
        plan: response.data.plan,
        permission: response.data.permission,
        slackChannel: response.data.slackChannel,
      }),
    )

    return response.data.accessToken
  }
  return refresh
}

export default useRefreshToken
