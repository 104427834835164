import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//Images
import logo from "../../../assets/Formibly-logo.png";
import logoDark from "../../../assets/Formibly-white-logo.png";
//Clerk
import { useSignIn, useUser, useClerk } from "@clerk/clerk-react";
//Styles
import styles from "./login.module.css";
const ClerkLogin = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { signIn, isLoading, setActive } = useSignIn();
  const { isSignedIn, user } = useUser();
  const { signOut } = useClerk();
  const navigate = useNavigate();

  // New effect to clear messages after a delay
  useEffect(() => {
    let timer;
    if (errorMessage || successMessage) {
      timer = setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return () => clearTimeout(timer);
  }, [errorMessage, successMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!email || !password) {
      console.log("Please enter both email and password");
      setErrorMessage("Please enter both email and password");
      return;
    }

    try {
      const result = await signIn.create({
        identifier: email,
        password,
      });

      if (result.status === "complete") {
    
        await setActive({ session: result.createdSessionId });

        if (result?.firstFactorVerification?.status !== "verified") {
          setErrorMessage(
            "Your account is not verified. Please verify your email to log in."
          );
          await signOut();
          return;
        }
        console.log("Login successful");
        navigate("/forms");
      } else {
        console.log("Login failed");
        setErrorMessage("Login failed. Please try again.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      if (err?.errors && err?.errors?.length > 0) {
        setErrorMessage(err?.errors[0]?.message);
      } else {
        setErrorMessage("An error occurred during login. Please try again.");
      }
    }
  };
  const handleSignOut = async () => {
    try {
      await signOut()
      window.location.reload()
      console.log('User signed out successfully')
    } catch (err) {
      console.error('Error signing out:', err)
      setErrorMessage('An error occurred while signing out. Please try again.')
    }
  }
  const handleResetPassword = async () => {
    setIsResettingPassword(true);
    if (!email) {
      setErrorMessage("Please enter your email address to reset your password");
      setSuccessMessage("");
      return;
    }

    try {
      await signIn.create({
        strategy: "reset_password_email_code",
        identifier: email,
      });

      setSuccessMessage(
        "Password reset email sent. Please check your inbox for the reset code."
      );
    } catch (err) {
      console.error("Error sending reset password email:", err);
      if (err.errors && err.errors.length > 0) {
        // Use the specific error message from Clerk
        setErrorMessage(err.errors[0].message);
      } else if (err.message) {
        // Use a general error message from the error object
        setErrorMessage(err.message);
      } else {
        // Fallback error message
        setErrorMessage(
          "An error occurred while sending the reset email. Please try again."
        );
      }
      setSuccessMessage("");
    }
  };

  const handleBackToLogin = () => {
    setIsResettingPassword(false);
    setErrorMessage("");
  };
  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*]/;

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasNumber.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar.test(password)) {
      return "Password must contain at least one special character.";
    }
    return "";
  };
  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (!resetCode || !newPassword) {
      setErrorMessage("Please enter both the reset code and new password");
      return;
    }
    const passwordValidationError = validatePassword(newPassword);
    if (passwordValidationError) {
      setErrorMessage(passwordValidationError);
      return;
    }
    try {
      const result = await signIn.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code: resetCode,
        password: newPassword,
      });

      if (result.status === "complete") {
        setErrorMessage(
          "Password reset successful. You can now log in with your new password."
        );
        setIsResettingPassword(false);
      } else {
        setErrorMessage("Password reset failed. Please try again.");
      }
    } catch (err) {
      console.error("Error resetting password:", err);
      setErrorMessage(
        "An error occurred while resetting password. Please try again."
      );
    }
  };

  if (isSignedIn) {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.signedIn}>
          <p className="text-lg font-semibold">
            Welcome,{" "}
            {user.firstName ||
              user.username ||
              user.emailAddresses[0].emailAddress}
            !
          </p>
          <button onClick={handleSignOut} className="btn btn-primary btn-block">
            Sign Out
          </button>
          <Link to="/forms" className="btn btn-primary btn-block btn-outline">
            Go to dashboard
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-[100vw] flex justify-center items-center px-2 bg-base-100 ">
      <form
        onSubmit={
          isResettingPassword ? handleResetPasswordSubmit : handleSubmit
        }
        className={`${styles.form}`}
      >
        {/* Logo/Message Section */}
        <div className="flex justify-center items-center w-full h-20">
          {errorMessage && (
            <div
              className="flex justify-center items-center px-4 w-full h-full rounded border border-error text-error bg-error/10"
              role="alert"
            >
              <span className="text-center">{errorMessage}</span>
            </div>
          )}
          {successMessage && (
            <div
              className="flex justify-center items-center px-4 w-full h-full rounded border text-success border-success bg-success/10"
              role="alert"
            >
              <span className="text-center">{successMessage}</span>
            </div>
          )}
          {!errorMessage && !successMessage && (
            <div className="flex justify-center items-center w-full h-full">
              <img
                src={theme === "formibly" ? logo : logoDark}
                alt="Formibly Logo"
                className="w-auto max-h-full"
              />
            </div>
          )}
        </div>

        {/* Form Section */}
        <div className="flex flex-col flex-grow justify-center">
          {isResettingPassword ? (
            <div>
              <input
                type="text"
                id="resetCode"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                required
                placeholder="Reset Code"
                className="w-full input input-bordered"
              />

              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                placeholder="New Password"
                className="w-full input input-bordered"
              />
            </div>
          ) : (
            <div className="w-full">
              <input
                type="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full input input-bordered"
              />

              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full input input-bordered"
              />
            </div>
          )}
        </div>

        {/* Bottom Links/Buttons Section */}
        <div className="text-center">
          {isResettingPassword ? (
            <>
              <button
                type="submit"
                onClick={handleResetPasswordSubmit}
                className="btn btn-primary btn-block"
              >
                Reset Password
              </button>
              <button
                type="button"
                onClick={handleBackToLogin}
                className="mt-2 btn btn-outline btn-primary btn-block"
              >
                Back to Login
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
                className="btn btn-primary btn-block"
              >
                {isLoading ? "Logging in..." : "Log in"}
              </button>
              <p className="mt-2 text-sm">
                Don't have an account?{" "}
                <Link to="/register" className="text-primary">
                  Sign up here
                </Link>
              </p>

              <button
                onClick={handleResetPassword}
                className="text-sm text-primary"
              >
                Forgot password?
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ClerkLogin;
