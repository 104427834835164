import React from 'react'
import { useSelector } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { IoChevronBack } from 'react-icons/io5'

const Payment = ({ updateError, changePage, changePayment, submit }) => {
  const theme = useSelector((state) => state.theme.theme)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    // Check if cardElement is null
    if (!cardElement) {
      updateError('Card element is not available.')

      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      updateError(error.message)
    } else {
      changePayment(paymentMethod.id)
      submit()
    }
  }

  return (
    <div>
      <article className="mb-5">
        <p>
          Just a friendly reminder, the product you are about to subscribe to
          costs <strong>$100 per month</strong>.
        </p>
        <p>
          By confirming your registration, you'll soon be on your way to reaping
          the vast benefits it offers.
        </p>
        <p>
          We are excited for you to join our community and start your journey
          with us.
        </p>
        <p>
          Hit the{' '}
          <span className="text-[#F15959] font-semibold">
            'Pay and register'
          </span>{' '}
          button to proceed.
        </p>
      </article>

      <div className="px-2 rounded-md border-2 border-gray-300">
        <CardElement
          options={{
            style: {
              base: {
                color: theme === 'formibly' ? '#32325d' : '#fff',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '1rem',
                lineHeight: '36px', // Increased line height for more height
                '::placeholder': {
                  color: '#aab7c4',
                },
                padding: '10px', // Added padding for better spacing
                border: '1px solid #d1d1d1', // Added light border
                borderRadius: '4px', // Optional: add border radius for better aesthetics
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Added shadow for better visibility
              },
              invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
              },
            },
          }}
        />
      </div>
      <div>
        <button
          type="button"
          disabled={!stripe}
          onClick={handleSubmit}
          className="mt-2 w-full btn btn-primary"
        >
          Pay and register
        </button>
        <div className="flex gap-[2px] justify-center items-center text-sm cursor-pointer mt-2 ">
          <IoChevronBack className="relative top-[2px]" />

          <p
            onClick={() => {
              changePage(1)
            }}
          >
            go to previous page
          </p>
        </div>
      </div>
    </div>
  )
}

export default Payment
