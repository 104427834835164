import { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import DashboardLayout from 'components/DashboardLayout.js'
import { useDispatch } from 'react-redux'
import { SignedIn, SignedOut } from '@clerk/clerk-react'
//Pages
import Missing from 'pages/Missing.js'
import Register from 'pages/Home/Register/Register.js'
import Login from 'pages/Home/Login/Login.js'
//Pages dashboard
import Documents from 'pages/Dashboard/Documents/Documents.js'
import Settings from 'pages/Dashboard/Settings/Settings.js'
import Accounts from 'pages/Dashboard/Accounts'
import Websites from 'pages/Dashboard/Website/Websites'
import Forms from 'pages/Dashboard/Forms/Forms'
import Modal from 'components/Modal.js'

//Theme
import { setTheme } from 'features/theme/themeSlice'
function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    const theme = localStorage.getItem('theme') || 'formibly'
    document.documentElement.setAttribute('data-theme', theme)
    dispatch(setTheme(theme))
  }, [])

  return (
    <>
      <Modal />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          element={
            <>
              <SignedIn>
                <DashboardLayout />
              </SignedIn>
              <SignedOut>
                <Navigate to="/login" replace />
              </SignedOut>
            </>
          }
        >
          <Route path="/websites" element={<Websites />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/accounts" element={<Accounts />} />
        </Route>

        <Route path="unauthorized" element={<Missing />} />
        <Route path="*" element={<Missing />}></Route>
      </Routes>
    </>
  )
}

export default App
