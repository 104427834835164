import { Outlet } from 'react-router-dom'

//Components
import Nav from './DashboardNav'
import DashboardNavTop from './DashboardNavTop'
import APIKeysModal from './APIKeysModal'
const DashboardLayout = () => {
  return (
    <main className="min-h-[100vh]">
      <APIKeysModal />
      <div>
        <div>
          <DashboardNavTop />
          <div className="relative p-[5vw] sm:p-5  xl:pl-[320px]">
            <Nav />
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default DashboardLayout
//xl:pl-[300px]
