import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'api/axios'

// Helper function to get the token
const getToken = async () => {
  // We'll use the global Clerk object, which should be available after Clerk is initialized
  if (!window.Clerk) {
    throw new Error('Clerk is not initialized')
  }
  const session = await window.Clerk.session
  if (!session) {
    throw new Error('No active session')
  }
  return session.getToken()
}

export const addWebsiteAsync = createAsyncThunk(
  'websites/addWebsiteAsync',
  async ({ name }, { rejectWithValue }) => {
    try {
      const token = await getToken()
      const response = await axiosPrivate.post(
        '/website/add',
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return response.data.website
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const fetchWebsitesAsync = createAsyncThunk(
  'websites/fetchWebsitesAsync',
  async (_, { rejectWithValue }) => {
    try {
      const token = await getToken()
      const response = await axiosPrivate.get('/website/get', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const editWebsiteNameAsync = createAsyncThunk(
  'websites/editWebsiteName',
  async ({ id, name }, { rejectWithValue }) => {
    try {
      const token = await getToken()
      await axiosPrivate.patch(
        '/website/changeName',
        { id, name },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return { id, name }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const removeWebsiteAsync = createAsyncThunk(
  'websites/removeWebsite',
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = await getToken()
      await axiosPrivate.delete(`/website/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return id
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const toggleNotifications = createAsyncThunk(
  'websites/toggleNotifications',
  async ({ id, notifications }, { rejectWithValue }) => {
    try {
      const token = await getToken()
      await axiosPrivate.patch(
        '/website/changeNotifications',
        { id, notifications },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return { id, notifications }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const toggleActive = createAsyncThunk(
  'websites/toggleActive',
  async ({ id, active }, { rejectWithValue }) => {
    try {
      const token = await getToken()
      await axiosPrivate.patch(
        '/website/changeActive',
        { id, active },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return { id, active }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const addIntegration = createAsyncThunk(
  'websites/addIntegration',

  async ({ id, type, key, secret }, { rejectWithValue }) => {
    try {
      const token = await getToken()
      await axiosPrivate.post(
        '/website/addApiKey',
        { id, type, key, secret },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return { id, type, key, secret }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data)
    }
  },
)

const initialState = []

const websiteSlice = createSlice({
  name: 'websites',
  initialState,
  reducers: {
    addWebsite: (state, action) => {
      console.log(action.payload)
      state.push(action.payload)
    },
    clearWebsites: (state) => {
      return []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsitesAsync.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(editWebsiteNameAsync.fulfilled, (state, action) => {
        const { id, name } = action.payload
        const website = state.find((website) => website.id === id)
        if (website) {
          website.name = name
        }
      })
      .addCase(editWebsiteNameAsync.rejected, (state, action) => {
        console.error('Failed to edit website name:', action.error)
      })
      .addCase(removeWebsiteAsync.fulfilled, (state, action) => {
        const index = state.findIndex(
          (website) => website.id === action.payload,
        )
        if (index !== -1) {
          state.splice(index, 1)
        }
      })
      .addCase(addWebsiteAsync.fulfilled, (state, action) => {
        state.push(action.payload)
      })
      .addCase(toggleNotifications.fulfilled, (state, action) => {
        const { id, notifications } = action.payload
        const website = state.find((website) => website.id === id)
        if (website) {
          website.notifications = notifications
        }
      })
      .addCase(toggleActive.fulfilled, (state, action) => {
        const { id, active } = action.payload
        const website = state.find((website) => website.id === id)
        if (website) {
          website.active = active
        }
      })
      .addCase(addIntegration.fulfilled, (state, action) => {
        const { id, type, key, secret } = action.payload
        console.log(action.payload)
        const website = state.find((website) => website.id === id)
        console.log(website)
        if (website) {
          const integration = website.integrations.find(
            (integration) => integration.type === type,
          )
          if (integration) {
            console.log(integration)
            integration.key = key
            integration.secret = secret
          } else {
            website.integrations.push({ type, key })
          }
        }
      })
  },
})
export const { addWebsite, clearWebsites } = websiteSlice.actions
export default websiteSlice.reducer
