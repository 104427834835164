import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosPrivate from 'api/axios'

// Helper function to get the token
const getToken = async () => {
  // We'll use the global Clerk object, which should be available after Clerk is initialized
  if (!window.Clerk) {
    throw new Error('Clerk is not initialized')
  }
  const session = await window.Clerk.session
  if (!session) {
    throw new Error('No active session')
  }
  return session.getToken()
}

export const fetchFormsAsync = createAsyncThunk(
  'forms/fetchFormsAsync',
  async () => {
    const token = await getToken()
    const response = await axiosPrivate.get('/form/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  },
)

export const addFormAsync = createAsyncThunk(
  'forms/addFormAsync',
  async ({ name, type, url, websites, formId }) => {
    const token = await getToken()
    const response = await axiosPrivate.post(
      '/form/add',
      { name, type, url, websites, formId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    console.log(response.data.form)
    return response.data.form
  },
)

export const updateFormAsync = createAsyncThunk(
  'forms/updateFormAsync',
  async (form) => {
    const token = await getToken()
    await axiosPrivate.put(
      `/form/update`,
      {
        id: form.id,
        name: form.name,
        type: form.type,
        url: form.url,
        websites: form.websiteIds,
        testFrequency: form.testFrequency,
        emailNotifications: form.emailNotifications,
        slackNotifications: form.slackNotifications,
        active: form.active,
        testEmail: form.testEmail,
        redirect: form.redirect,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return form
  },
)

export const updateWebsites = createAsyncThunk(
  'forms/updateWebsites',
  async ({ formId, websites }) => {
    console.log(formId, websites)
    const token = await getToken()
    await axiosPrivate.put(
      '/form/update-websites',
      { formId, websites },
      { headers: { Authorization: `Bearer ${token}` } },
    )
    return { formId, websites }
  },
)

export const removeFormAsync = createAsyncThunk(
  'forms/removeFormAsync',
  async (id) => {
    const token = await getToken()
    await axiosPrivate.delete(`/form/remove/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return id
  },
)

const initialState = []

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    addForm: (state, action) => {
      state.push(action.payload)
    },
    clearForms: (state) => {
      return []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormsAsync.fulfilled, (state, action) => {
      return action.payload
    })

    builder.addCase(addFormAsync.fulfilled, (state, action) => {
      state.push(action.payload)
    })
    builder.addCase(removeFormAsync.fulfilled, (state, action) => {
      return state.filter((form) => form.id !== action.payload)
    })
    builder.addCase(updateFormAsync.fulfilled, (state, action) => {
      const index = state.findIndex((form) => form.id === action.payload.id)
      if (index !== -1) {
        state.splice(index, 1, action.payload)
      }
    })
    builder.addCase(updateWebsites.fulfilled, (state, action) => {
      const index = state.findIndex((form) => form.id === action.payload.formId)

      if (index !== -1) {
        state[index].websites = action.payload.websites
      }
    })
  },
})

export const { addForm, clearForms } = formsSlice.actions
export default formsSlice.reducer
