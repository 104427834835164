import React from 'react'
import { useNavigate } from 'react-router-dom'

const Missing = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  return (
    <section className="px-4 flex  flex-col items-center justify-center h-[100vh] bg-base-100">
      <div>
        <p className="text-lg font-semibold max-w-[400px] ">
          You do not have access to the requested page or it is non-existent
        </p>
        <button onClick={goBack} className="mt-5 btn btn-primary">
          Go Back
        </button>
      </div>
    </section>
  )
}

export default Missing
