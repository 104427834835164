import React from 'react'
//Components
import TabsContainer from './components/Tabs/TabsContainer.js'
//Hooks
import { useLocation } from 'react-router-dom'

function App() {
  const location = useLocation()

  const selectedWebsite = location.state?.website

  return <TabsContainer selectedWebsite={selectedWebsite} />
}

export default App
