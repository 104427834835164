import React, { useState } from 'react'
import { useSignUp} from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'
//Components
import Loading from '../../../../components/Loading'

//Helper
import axios from '../../../../api/axios'
const VerificationCode = ({
  updateError,
  userInfo,
  paymentMethod,
  changePage,
}) => {
  const { isLoaded, signUp, setActive } = useSignUp()

  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)

  const handleVerify = async () => {
    if (!isLoaded) {
      return
    }

    try {
      setLoading(true)
      const verificationResult = await signUp.attemptEmailAddressVerification({
        code,
      })
      try {
        const response = await axios.post(
          '/user/register',
          {
            payment_method: paymentMethod,
            email: userInfo.email,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            pwd: userInfo.password,
            confirm_pwd: userInfo.confirm_pwd,
            plan: 'Pro',
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        )
        console.log(response)
      } catch (err) {
        const deleteUser = await axios.post(
          '/user/delete-clerk-user',
          {
            clerkUserId: verificationResult.createdUserId,
          },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          },
        )
        console.log(deleteUser)
        changePage(1)

        updateError('Something went wrong creating new user, please try again')
        return
      }
      await setActive({ session: verificationResult.createdSessionId })
      navigate('/forms')

      // If backend registration is successful, complete the Clerk sign-up
    } catch (err) {
      console.error('Error verifying email', err)
      updateError(
        err.errors?.[0]?.message || 'An error occurred during verification.',
      )
    } finally {
      setLoading(false)
    }
  }

  const handleResend = async () => {
    if (!isLoaded) {
      return
    }

    try {
      setLoading(true)
      await signUp.prepareEmailAddressVerification({
        strategy: 'email_code',
      })
    } catch (err) {
      console.error('Error resending verification code', err)

      console.log(err.errors?.[0]?.message)
      updateError(
        err.errors?.[0]?.message ||
          'An error occurred while resending the code.',
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <input
        placeholder="Verification Code"
        className="w-full input input-bordered"
        value={code}
        onChange={(e) => {
          setCode(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
      />
      <button type="button" onClick={handleVerify} className='btn btn-primary btn-block'>
        Verify
      </button>
      <div className="h-[40px]  flex justify-center items-center">
        {loading ? (
          <Loading />
        ) : (
          <button
            type="button"
            onClick={handleResend}
            className='text-sm'
          >
            Resend Verification Code
          </button>
        )}
      </div>
    </div>
  )
}

export default VerificationCode
