import { useUser } from '@clerk/clerk-react'
import { useState, useRef, useCallback } from 'react'
import { CiEdit } from 'react-icons/ci'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { setError } from '../../../../../features/error/errorSlice'
import { useAuth } from '@clerk/clerk-react'
import axios from '../../../../../api/axios'

const UserInfo = () => {
  const { getToken } = useAuth()
  const dispatch = useDispatch()
  const { user } = useUser()
  const [imageUrl, setImageUrl] = useState(user.imageUrl)
  const [isLoading, setIsLoading] = useState(false)
  const fileInputRef = useRef(null)

  const [isChangingPassword, setIsChangingPassword] = useState(false)

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)

  const handleNameChange = async (event) => {
    event.preventDefault()
    try {
      await user.update({
        firstName: firstName,
        lastName: lastName,
      })
    } catch (err) {
      console.error('Error updating name:', err)
      dispatch(setError('Failed to update name'))
    }
  }

  const handleImageClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = useCallback(
    async (event) => {
      const file = event.target.files[0]
      if (file) {
        setIsLoading(true)
        setError(null)
        try {
          await user.setProfileImage({ file })
          const updatedUser = await user.reload()
          setImageUrl(updatedUser.imageUrl)
        } catch (err) {
          dispatch(setError('Failed to update profile image'))
          console.error('Error updating profile image:', err)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [user],
  )

  const handlePasswordChange = async (event) => {
    event.preventDefault()
    setIsChangingPassword(true)

    const oldPassword = event.target.oldPassword.value
    const newPassword = event.target.newPassword.value

    try {
      await user.updatePassword({
        currentPassword: oldPassword,
        newPassword: newPassword,
      })

      event.target.reset()
    } catch (err) {
      console.error('Error updating password:', err.errors)
      const error = err.errors[0]
      if (error.code === 'form_password_validation_failed') {
        dispatch(setError('Old password is incorrect'))
        return
      }

      dispatch(setError(err.errors[0].message))
    } finally {
      setIsChangingPassword(false)
    }
  }
  const handleDeleteAccount = async (event) => {
    event.preventDefault()
    try {
      const token = await getToken()

      await axios.delete('/user/delete-user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      document.getElementById('my_modal_1').showModal()
      await user.delete()
    } catch (err) {
      console.error('Error deleting account:', err)
      dispatch(setError('Failed to delete account'))
    }
  }
  return (
    <div>
      <div className="flex flex-wrap gap-2 justify-center items-center sm:justify-start">
        <div className="overflow-hidden relative w-32 h-32 rounded-full group">
          <img
            src={imageUrl}
            alt="User profile"
            className="object-cover w-full h-full"
          />
          <div
            onClick={handleImageClick}
            className="hidden absolute top-0 left-0 justify-center items-center w-full h-full cursor-pointer bg-black/30 group-hover:flex"
          >
            <CiEdit size={30} />
          </div>
          {isLoading && (
            <div className="flex absolute top-0 left-0 z-20 justify-center items-center w-full h-full bg-black/50">
              <AiOutlineLoading3Quarters className="animate-spin" size={30} />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-start">
          <h1 className="text-2xl italic font-normal">
            {user.firstName} {user.lastName}
          </h1>
          <h2 className="font-normal">
            {user.primaryEmailAddress.emailAddress}
          </h2>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => document.getElementById('my_modal_1').showModal()}
          >
            Delete Account
          </button>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
      <div className="flex flex-col gap-10 items-center mt-10 sm:flex-row">
        <div className="w-full  max-w-[300px]">
          <h3>Change Password</h3>
          <form
            onSubmit={handlePasswordChange}
            className="flex flex-col items-start w-full gap"
          >
            <input
              type="password"
              name="oldPassword"
              placeholder="Current Password"
              required
              className="input input-bordered input-sm !bg-base-100 text-base-content w-full"
            />
            <input
              type="password"
              name="newPassword"
              placeholder="New Password"
              required
              className="input input-bordered input-sm !bg-base-100 text-base-content w-full"
            />

            <button
              type="submit"
              disabled={isChangingPassword}
              className="btn btn-sm btn-primary"
            >
              {isChangingPassword ? 'Changing...' : 'Change Password'}
            </button>
          </form>
        </div>
        <div className="w-full  max-w-[300px]">
          <h3>Change Name</h3>
          <form
            onSubmit={handleNameChange}
            className="flex flex-col items-start gap"
          >
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              required
              className="input input-bordered input-sm !bg-base-100 text-base-content w-full"
            />
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              required
              className="input input-bordered input-sm !bg-base-100 text-base-content w-full"
            />
            <button type="submit" className="btn btn-sm btn-primary">
              Change Name
            </button>
          </form>
        </div>
      </div>

      <dialog id="my_modal_1" className="modal">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Deleting Account</h3>
          <p className="py-4">Are you sure you want to delete your account?</p>
          <div className="modal-action">
            <form method="dialog" onSubmit={handleDeleteAccount}>
              <button className="btn">Yes</button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  )
}

export default UserInfo
