import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//Components
import UserInfo from './Components/UserInfo'
import Payment from './Components/Payment'
import VerificationCode from './Components/VerificationCode'
import Loading from '../../../components/Loading'
//styles
import styles from './register.module.css'
//images
import Logo from 'assets/Formibly-logo.png'
import LogoDark from 'assets/Formibly-white-logo.png'
//Stripe
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
//Clerk
import { useSignUp } from '@clerk/clerk-react'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const Register = () => {
  const theme = useSelector((state) => state.theme.theme)
  const { signUp } = useSignUp()
  const [page, setPage] = useState(1)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [paymentMethod, setPaymentMethod] = useState(null)
  //Clear error
  useEffect(() => {
    const timer = setTimeout(() => {
      setError('')
    }, 3000)

    return () => clearTimeout(timer)
  }, [error])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await signUp.create({
        emailAddress: userInfo.email,
        password: userInfo.password,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      })
      await signUp.prepareEmailAddressVerification({ strategy: 'email_code' })
      setPage(3)
    } catch (err) {
      console.error(err)
      if (err.errors) {
        setError(
          err.errors[0]?.message || 'An error occurred during user creation.',
        )
      } else {
        setError('An unexpected error occurred.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.register_page}>
      <form className={styles.form}>
        <div className={styles.logo_container}>
          {error ? (
            <div className={styles.error_container}>
              <p>{error}</p>
            </div>
          ) : (
            <img
              src={theme === 'formibly' ? Logo : LogoDark}
              alt="Formibly logo"
            />
          )}
        </div>

        {page === 1 && (
          <UserInfo
            updateError={setError}
            changePage={setPage}
            setUserInfo={setUserInfo}
          />
        )}

        {page === 2 &&
          (loading ? (
            <div className="flex flex-1 justify-center items-center w-full h-[200px]">
              <Loading />
            </div>
          ) : (
            <Elements stripe={stripePromise}>
              <Payment
                updateError={setError}
                changePage={setPage}
                submit={handleSubmit}
                changePayment={setPaymentMethod}
              />
            </Elements>
          ))}
        {page === 3 && (
          <VerificationCode
            updateError={setError}
            changePage={setPage}
            userInfo={userInfo}
            paymentMethod={paymentMethod}
          />
        )}
      </form>
    </div>
  )
}

export default Register
