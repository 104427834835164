import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
const UserInfo = ({ updateError, changePage, setUserInfo }) => {
  const [firstName, setFirstName] = useState(
    localStorage.getItem('firstName') || '',
  )
  const [lastName, setLastName] = useState(
    localStorage.getItem('lastName') || '',
  )
  const [email, setEmail] = useState(localStorage.getItem('email') || '')
  const [password, setPassword] = useState(
    localStorage.getItem('password') || '',
  )

  const handleNext = async () => {
    //Input validation
    if (!firstName.trim()) {
      updateError('First name is required')
      return
    }
    if (!lastName.trim()) {
      updateError('Last name is required')
      return
    }
    if (!email.trim()) {
      updateError('Email is required')
      return
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      updateError('Invalid email format')
      return
    }
    if (!password.trim()) {
      updateError('Password is required')
      return
    }
    if (password.length < 8) {
      updateError('Password must be at least 8 characters long')
      return
    }
    if (!/[A-Z]/.test(password)) {
      updateError('Password must contain at least one uppercase character')
      return
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      updateError('Password must contain at least one symbol')
      return
    }

    setUserInfo({
      firstName,
      lastName,
      email,
      password,
    })
    changePage(2)
  }

  useEffect(() => {
    localStorage.setItem('firstName', firstName)
    localStorage.setItem('lastName', lastName)
    localStorage.setItem('email', email)
    localStorage.setItem('password', password)
  }, [firstName, lastName, email, password])
  return (
    <div>
      <input
        type="text"
        placeholder="First Name"
        className="w-full input input-bordered"
        value={firstName}
        onChange={(e) => {
          setFirstName(e.target.value)
        }}
      />

      <input
        type="text"
        placeholder="Last Name"
        className="w-full input input-bordered"
        value={lastName}
        onChange={(e) => {
          setLastName(e.target.value)
        }}
      />

      <input
        type="text"
        placeholder="Email"
        className="w-full input input-bordered"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      />

      <input
        type="text"
        placeholder="Password"
        className="w-full input input-bordered"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
        }}
      />

      <button
        type="button "
        className="w-full btn btn-primary"
        onClick={handleNext}
      >
        Proceed to payment
      </button>
      <Link to="/">
        <p className="mt-4 text-sm text-center text-primary">Already have an account? Login</p>
      </Link>
    </div>
  )
}

export default UserInfo
