import React, { useState } from 'react'
import { FaArrowDownWideShort } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import Loading from '../../../../components/Loading'
const FilterForms = ({
  changeFilter,
  setFilterWebsite,
  website,
  filter,
  clearFilters,
  loading,
}) => {
  const [websiteIsOpen, setWebsiteIsOpen] = useState(false)
  const allWebsites = useSelector((state) => state.websites)

  const websiteOptions = allWebsites?.map((website) => ({
    value: website.id,
    label: website.name,
  }))
  websiteOptions.unshift({ value: null, label: 'All Websites' })
  const truncateLabel = (label, maxLength) => {
    return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label
  }
  return (
    <div>
      <div className="flex flex-col gap-2 items-center mt-10 md:gap-4 md:flex-row">
        <div className="w-full md:max-w-[600px] flex gap-2">
          <div className="flex flex-col flex-1 gap-2 md:flex-row">
            <button
              className={`btn btn-sm ${
                filter.includes('deactivated') && 'btn-active btn-primary'
              } flex-1`}
              onClick={() => {
                changeFilter('deactivated')
              }}
            >
              Deactivated
            </button>
            <button
              className={`btn btn-sm ${
                filter.includes('notifications') && 'btn-active btn-primary'
              } flex-1`}
              onClick={() => {
                changeFilter('notifications')
              }}
            >
              Notifications muted
            </button>
          </div>
          <div className="flex flex-col flex-1 gap-2 md:flex-row">
            <button
              className={`btn btn-sm ${
                filter.includes('failed') && 'btn-active btn-primary'
              } flex-1`}
              onClick={() => {
                changeFilter('failed')
              }}
            >
              Failed
            </button>
            <details className="flex-1 dropdown" open={websiteIsOpen}>
              <summary
                className="w-full btn btn-sm"
                onClick={(event) => {
                  event.preventDefault()
                  setWebsiteIsOpen((isOpen) => !isOpen)
                }}
              >
                {loading ? (
                  <Loading />
                ) : (
                  <div className="flex flex-row justify-between items-center w-full no-wrap" >
                    <p>
                      {website ? truncateLabel(website.label, 10) : 'Website'}{' '}
                    </p>
                    <FaArrowDownWideShort />
                  </div>
                )}
              </summary>
              <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                {websiteOptions.map((option) => (
                  <li key={option.label}>
                    <button
                      onClick={(event) => {
                        event.preventDefault()
                        setFilterWebsite(option)
                        setWebsiteIsOpen(false)
                      }}
                    >
                      {option.label}
                    </button>
                  </li>
                ))}
              </ul>
            </details>
          </div>
        </div>
        <button
          className="self-center btn btn-sm btn-primary"
          onClick={clearFilters}
        >
          Clear
        </button>
      </div>
    </div>
  )
}

export default FilterForms
