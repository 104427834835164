import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { useEffect, useState } from 'react'

const ADD_URL = 'account/add'
const LIST_URL = 'account/list'
const CHANGE_PERMISSION_URL = 'account/change-permission'
const Accounts = () => {
  const axiosPrivate = useAxiosPrivate()
  const [accounts, setAccounts] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axiosPrivate.post(ADD_URL, {
        firstName: e.target.firstName.value,
        lastName: e.target.lastName.value,
        email: e.target.email.value,
        pwd: e.target.password.value,
        permission: e.target.permission.value,
      })
    } catch (err) {
      alert(err.message)
      console.error(err.message)
    }
  }

  const handlePermissionChange = async (e, accountId) => {
    e.preventDefault()
    const newPermission = e.target.value
    try {
   await axiosPrivate.post(CHANGE_PERMISSION_URL, {
        accountId,
        permissionId: newPermission,
      })
      const updatedPermission = accounts.map((account) => {
        if (account.id === accountId) {
          return {
            ...account,
            permission: newPermission,
          }
        } else {
          return account
        }
      })
      console.log(updatedPermission)
      setAccounts(updatedPermission)
    } catch (err) {
      alert(err.message)
      console.error(err.message)
    }
  }

  useEffect(() => {
    const gatherAccounts = async () => {
      try {
        const response = await axiosPrivate.get(LIST_URL)

        setAccounts(response?.data?.accounts)
      } catch (err) {
        alert(err.message)
        console.error(err.message)
      }
    }
    gatherAccounts()
  }, [])

  return (
    <div className="flex flex-wrap gap-20">
      <form
        className="flex flex-col items-start justify-center gap-2"
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          className="px-3 py-1 shadow-sm"
        />
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          className="px-3 py-1 shadow-sm"
        />
        <input
          type="email"
          placeholder="email"
          className="px-3 py-1 shadow-sm"
          name="email"
        />
        <input
          type="password"
          placeholder="password"
          className="px-3 py-1 shadow-sm"
          name="password"
        />
        <select className="px-3 py-1 shadow-sm" name="permission">
          <option value="user">User</option>
          <option value="admin">Editor</option>
          <option value="admin">Admin</option>
          {/* Add more options as needed */}
        </select>
        <button className="mt-5 btn">Create</button>
      </form>
      <ol>
        {accounts.map((account, index) => (
          <li
            className="flex items-center justify-center gap-2 px-4 py-2 shadow-md"
            key={index}
          >
            <p>{account?.firstName}</p>
            <p>{account?.lastName}</p>
            <select
              className="px-3 py-1 ml-5 shadow-sm"
              value={account?.permission}
              onChange={(e) => {
                handlePermissionChange(e, account?.id)
              }}
            >
              <option value="user">User</option>
              <option value="editor">Editor</option>
              <option value="admin">Admin</option>
            </select>
          </li>
        ))}
      </ol>
    </div>
  )
}
export default Accounts
