import React, { useEffect, useState } from 'react'
import { useUser, useSession } from '@clerk/clerk-react'
import axios from '../../../../../api/axios'
//Hooks
import { useDispatch } from 'react-redux'
import { setError } from '../../../../../features/error/errorSlice'
const Notifications = () => {
  const dispatch = useDispatch()
  const [slackChannel, setSlackChannel] = useState('')
  const { user } = useUser()
  const { session } = useSession()
  const email = user.emailAddresses[0].emailAddress
  const getToken = async () => {
    const token = await session.getToken()
    return token
  }

  useEffect(() => {
  
    const fetchData = async () => {
      try {
        const token = await getToken()

        const res = await axios.get('/slack/getSlackChannel', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        setSlackChannel(res.data.slackChannel)
      } catch (err) {
        dispatch(setError('Error fetching slack channel'))
      }
    }
    fetchData()
  }, [])

  return (
    <div>
      <h2 className="text-lg font-semibold">Slack notifications</h2>
      <div className="max-w-lg divider"></div>
      <p>
        {slackChannel && 'Notifications go to:'} {slackChannel || ''}
      </p>
      <button className="mt-2 btn btn-primary btn-sm">
        <a
          href={`https://slack.com/oauth/v2/authorize?client_id=215881133136.7237385815187&scope=incoming-webhook,chat:write,channels:join&redirect_uri=${process.env.REACT_APP_BASE_URL}/slack/auth&state=${email}`}
        >
          {slackChannel ? 'Change Channel' : 'Install Slack'}
        </a>
      </button>
      <h2 className="mt-10 text-lg font-semibold">Email notifications</h2>
      <div className="max-w-lg divider"></div>
      <p>Email notifications go to: {user.emailAddresses[0].emailAddress}</p>
    </div>
  )
}

export default Notifications
