//Hooks
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Utils
import { fetchWebsitesAsync } from 'features/website/websiteSlice'
import { fetchFormsAsync } from 'features/forms/formsSlice'
import { setError } from 'features/error/errorSlice'
import { clearWebsites } from 'features/website/websiteSlice'
//Components
import Website from './components/Website'
import Loading from '../../../components/Loading'
import FilterWebsite from './components/FilterWebsite'
const Websites = () => {
  const dispatch = useDispatch()
  const forms = useSelector((state) => state.forms)
  const websites = useSelector((state) => state.websites)

  const [websitesLoading, setWebsitesLoading] = useState(true)
  const [formsLoading, setFormsLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setWebsitesLoading(true)
        await dispatch(clearWebsites())
        await dispatch(fetchWebsitesAsync())
      } catch (err) {
        await dispatch(setError('Failed to fetch websites'))
      } finally {
        setWebsitesLoading(false)
      }
      try {
        setFormsLoading(true)
        await dispatch(fetchFormsAsync())
      } catch (err) {
        await dispatch(setError('Failed to fetch forms'))
      } finally {
        setFormsLoading(false)
      }
    }
    fetchData()
  }, [dispatch])

  //Filter
  const [filter, setFilter] = useState('')
  const [filterName, setFilterName] = useState('')
  const [filteredWebsites, setFilteredWebsites] = useState(websites)

  useEffect(() => {
    setFilteredWebsites(websites)
  }, [websites])

  const changeFilter = (newFilter) => {
    filter === newFilter ? setFilter('') : setFilter(newFilter)
  }

  useEffect(() => {
    let filtered = websites

    if (filter === 'Deactivated') {
      filtered = filtered.filter((website) => !website.active)
    } else if (filter === 'Notifications') {
      filtered = filtered.filter((website) => !website.notifications)
    }

    if (filterName !== '') {
      filtered = filtered.filter((website) =>
        website.name.toLowerCase().includes(filterName.toLowerCase()),
      )
    }
    if (filter === 'Failed') {
      const newFiltered = websites.filter((website) => {
        const websiteForms = website.forms.map((form) =>
          forms.find((f) => f.id === form),
        )
        const failedForms = websiteForms.filter(
          (form) => form.status === 'Failed',
        )
        if (failedForms.length > 0) return true
      })
      filtered = newFiltered
    }
    setFilteredWebsites(filtered)
  }, [filter, filterName, websites])
  return (
    <section>
      <FilterWebsite
        filter={filter}
        changeFilter={changeFilter}
        setFilterName={setFilterName}
      />
      <div className="p-2 sm:p-10 mt-5 w-full rounded-2xl min-h-[150vh] bg-base-200">
        {websitesLoading && (
          <div className="flex gap-4 items-center">
            <Loading />
            <p>Loading</p>
          </div>
        )}

        {filteredWebsites?.map((website, index) => (
          <Website
            website={website}
            key={website.id + index}
            loading={formsLoading}
          />
        ))}
      </div>
    </section>
  )
}

export default Websites
