import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addIntegration } from 'features/website/websiteSlice'
import { setError } from 'features/error/errorSlice'
import { IoIosExit } from 'react-icons/io'
import { close } from 'features/apiKeys/apiKeysSlice'
const APIKeysModal = () => {
  const dispatch = useDispatch()
  const isOpenDefault = useSelector((state) => state.apiKeys.isOpen)

  const selectedWebsite = useSelector((state) => state.apiKeys.website)
  const selectedIntegration = useSelector((state) => state.apiKeys.integration)

  const websites = useSelector((state) => state.websites)
  const [isOpen, setIsOpen] = useState(isOpenDefault)
  useEffect(() => {
    setIsOpen(isOpenDefault)
  }, [isOpenDefault])
  //Inputs

  const [gravityKey, setGravityKey] = useState()
  const [gravitySecret, setGravitySecret] = useState()
  const [hubspotToken, setHubspotToken] = useState()
  const [formidableKey, setFormidableKey] = useState()
  const [typeformKey, setTypeformKey] = useState()
  const [jotformKey, setJotformKey] = useState()

  const [gravityKeyExists, setGravityKeyExists] = useState(false)
  const [gravitySecretExists, setGravitySecretExists] = useState(false)
  const [hubspotTokenExists, setHubspotTokenExists] = useState(false)
  const [formidableKeyExists, setFormidableKeyExists] = useState(false)
  const [typeformKeyExists, setTypeformKeyExists] = useState(false)
  const [jotformKeyExists, setJotformKeyExists] = useState(false)
  const closeModal = () => {
    dispatch(close())
  }
  const addKey = (type, key, secret) => {
    if ((type === 'Gravity' && !key) || (type === 'Gravity' && !secret)) {
      return dispatch(setError('Key or secret is missing'))
    } else if (type === 'Formidable' && !key) {
      return dispatch(setError('Key is missing'))
    } else if (type === 'Typeform' && !key) {
      return dispatch(setError('Key is missing'))
    } else if (type === 'Jotform' && !key) {
      return dispatch(setError('Key is missing'))
    }

    try {
      dispatch(
        addIntegration({
          id: selectedWebsite,
          type,
          key,
          secret,
        }),
      )
      closeModal()
    } catch (err) {
      dispatch(setError('Failed to add integration'))
    }
  }

  const modalTypes = [
    {
      name: 'HubSpot',
      content: (
        <div className="flex flex-col gap-5 justify-center items-center">
          <p className="w-full break-words">{hubspotToken}</p>
          <a
            href={`https://app-eu1.hubspot.com/oauth/authorize?client_id=4ec86cca-bf33-4f22-adbe-5c48d1c281c2&redirect_uri=${process.env.REACT_APP_BASE_URL}/website/oauth-callback&scope=oauth%20forms&state=${selectedWebsite}`}
            className="flex-2 btn btn-primary"
          >
            {hubspotTokenExists ? 'Reinstall HubSpot' : 'Install HubSpot'}
          </a>
        </div>
      ),
    },
    {
      name: 'Formidable',
      content: (
        <div className="flex flex-col gap-5 justify-center items-center">
          <input
            type="text"
            placeholder="Formidable - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setFormidableKey(e.target.value)
            }}
            value={formidableKey}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Formidable', formidableKey)
            }}
          >
            {formidableKeyExists ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
    {
      name: 'Typeform',
      content: (
        <div className="flex flex-col gap-5 justify-center items-center">
          <input
            type="text"
            placeholder="Typeform - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setTypeformKey(e.target.value)
            }}
            value={typeformKey}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Typeform', typeformKey)
            }}
          >
            {typeformKeyExists ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
    {
      name: 'Jotform',
      content: (
        <div className="flex flex-col gap-5 justify-center items-center">
          <input
            type="text"
            placeholder="Jotform - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setJotformKey(e.target.value)
            }}
            value={jotformKey}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Jotform', jotformKey)
            }}
          >
            {jotformKeyExists ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
    {
      name: 'Gravity',
      content: (
        <div className="flex flex-col gap-5">
          <input
            type="text"
            placeholder="Gravity - key"
            className="w-full input-bordered input"
            onChange={(e) => {
              setGravityKey(e.target.value)
            }}
            value={gravityKey}
          />
          <input
            type="text"
            placeholder="Gravity - secret"
            className="w-full input-bordered input"
            onChange={(e) => setGravitySecret(e.target.value)}
            value={gravitySecret}
          />
          <button
            className="btn"
            onClick={() => {
              addKey('Gravity', gravityKey, gravitySecret)
            }}
          >
            {gravityKeyExists && gravitySecretExists ? 'Reinstall' : 'Install'}
          </button>
        </div>
      ),
    },
  ]
  useEffect(() => {
    const website = websites.find((website) => website.id === selectedWebsite)
    const gravity = website?.integrations.find(
      (integration) => integration.type === 'Gravity',
    )
    const hubspot = website?.integrations.find(
      (integration) => integration.type === 'HubSpot',
    )

    const formidable = website?.integrations.find(
      (integration) => integration.type === 'Formidable',
    )
    const typeform = website?.integrations.find(
      (integration) => integration.type === 'Typeform',
    )
    const jotform = website?.integrations.find(
      (integration) => integration.type === 'Jotform',
    )
    setGravityKey(
      gravity?.key && gravity.key.trim() !== '' ? gravity.key : null,
    )
    setGravityKeyExists(gravity?.key && gravity.key.trim() !== '')
    setGravitySecret(
      gravity?.secret && gravity.secret.trim() !== '' ? gravity.secret : null,
    )
    setGravitySecretExists(gravity?.secret && gravity.secret.trim() !== '')
    setHubspotToken(hubspot?.accessToken || null)
    setHubspotTokenExists(
      hubspot?.accessToken && hubspot.accessToken.trim() !== '',
    )
    setFormidableKey(
      formidable?.key && formidable.key.trim() !== '' ? formidable.key : null,
    )
    setFormidableKeyExists(formidable?.key && formidable.key.trim() !== '')
    setTypeformKey(
      typeform?.key && typeform.key.trim() !== '' ? typeform.key : null,
    )
    setTypeformKeyExists(typeform?.key && typeform.key.trim() !== '')
    setJotformKey(
      jotform?.key && jotform.key.trim() !== '' ? jotform.key : null,
    )
    setJotformKeyExists(jotform?.key && jotform.key.trim() !== '')
  }, [selectedWebsite, websites])

  return (
    <dialog id="my_modal_2" className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box">
        <IoIosExit
          size={30}
          className="mb-4 ml-auto cursor-pointer hover:text-slate-400"
          onClick={closeModal}
        />

        {modalTypes.find((type) => type.name === selectedIntegration)?.content}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={closeModal}>close</button>
      </form>
    </dialog>
  )
}

export default APIKeysModal
