import React, { useState } from 'react'
//Components
import UserInfo from './userInfo'
import Integrations from './Integrations'
import Notifications from './Notifications'
const TabsContainer = ({ selectedWebsite }) => {
  const [activeTab, setActiveTab] = useState(selectedWebsite ? 'tab2' : 'tab1')

  const tabs = [
    { id: 'tab1', title: 'Credentials', content: <UserInfo /> },
    {
      id: 'tab2',
      title: 'API Keys',
      content: <Integrations website={selectedWebsite} />,
    },
    { id: 'tab3', title: 'Notifications', content: <Notifications /> },
  ]

  return (
    <div>
      <ul className="flex flex-wrap cursor-pointer">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className={`px-2 py-1 sm:px-4 sm:py-2 ${
              activeTab === tab.id
                ? 'text-primary border-b-2 border-primary'
                : 'text-gray-600'
            } `}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </li>
        ))}
      </ul>
      <div className="mt-10">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={activeTab === tab.id ? 'block' : 'hidden'}
          >
            {tab.content}
          </div>
        ))}
      </div>
     
    </div>
  )
}

export default TabsContainer
