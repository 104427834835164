import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
//Hooks
import { useLocation } from 'react-router-dom'
//React Icons
import { FiSun, FiMoon } from 'react-icons/fi'
import { setTheme } from '../features/theme/themeSlice'

const DashboardNav = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const themeDefault = useSelector((state) => state.theme.theme)

  const handleThemeChange = (e) => {
    const newTheme = e.target.checked ? 'formiblyDark' : 'formibly'
    document.documentElement.setAttribute('data-theme', newTheme)
    dispatch(setTheme(newTheme))
    localStorage.setItem('theme', newTheme)
  }

  return (
    <div
      className={`
        bg-base-200
        hidden
        xl:fixed
        xl:flex
        top-[0]
        left-0
        xl:!translate-x-0 
        z-10
        px-4 
        h-full
        transition-transform 
        duration-[0.8s]
        ease-in-out 
        flex-col 
        justify-between 
        shadow-xl 
        py-5 
        w-[90vw] 
        max-w-[300px] 
        `}
    >
      <div className="flex flex-col justify-between h-full">
        <ul className="mt-10 space-y-1">
          <li>
            <Link to="/forms">
              <button
                className={`btn w-full ${
                  location.pathname === '/forms' && 'btn-primary'
                } `}
              >
                Forms
              </button>
            </Link>
          </li>
          <li>
            <Link to="/websites">
              <button
                className={`btn w-full ${
                  location.pathname === '/websites' && 'btn-primary '
                } `}
              >
                Websites
              </button>
            </Link>
          </li>
        </ul>
        <div className="flex gap-2 justify-between items-center max-w-[200px] w-full  mx-auto">
          {themeDefault === 'formiblyDark' ? (
            <FiMoon size={20} />
          ) : (
            <FiSun size={20} />
          )}

          <p>{themeDefault === 'formiblyDark' ? 'Dark' : 'Light'}</p>
          <input
            type="checkbox"
            checked={themeDefault === 'formiblyDark'}
            onChange={handleThemeChange}
            className="mb-0 toggle theme-controller"
          />
        </div>
      </div>
    </div>
  )
}

export default DashboardNav
